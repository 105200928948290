const Carrer = () => {
    return (
        <div className="w-3/4 mx-auto mt-16" id="Carrer" data-aos="fade-up">
            <h1 className="text-4xl font-bold">My career</h1>
            <div className="grid md:grid-cols-2 sm:grid-cols-1 mt-8">

                <div className="flex flex-row ">
                    <div className="h-[450px] w-1 bg-white mr-5"></div>
                    <div>
                        <div className="mt-8">
                            <span className="text-textColor font-bold">Sep 2022 - Jun 2025</span>
                            <h1 className="font-bold">National School of Engineers of Carthage (ENI Carthage)</h1>
                            <h2>Engineering, software engineering</h2>
                        </div>
                        <div className="mt-8">
                            <span className="text-textColor font-bold">Sep 2019 - Jun 2022</span>
                            <h1 className="font-bold">Higher Institute of Technological Studies of Rades (ISET Rades)</h1>
                            <h2>Licence, Technologies de l'information</h2>
                        </div>
                        <div className="mt-8">
                            <span className="text-textColor font-bold">Sep 2015 - Jun 2019</span>
                            <h1 className="font-bold">Lycée Mohamed Brahmi Mourouj 6</h1>
                            <h2>Baccalauréat , Informatique</h2>
                        </div>
                    </div>
                </div>
                <div className="flex flex-row sm:mt-8 md:mt-0">
                    <div className="h-[450px] w-1 bg-white mr-5"></div>
                    <div>


                        <div className="mt-8">
                            <span className="text-textColor font-bold">Sep 2022 - Sep 2023</span>
                            <h1 className="font-bold">AURA SE</h1>
                            <h2>Full Stack Web Developer</h2>
                        </div>
                        <div className="mt-8">
                            <span className="text-textColor font-bold">Jan 2022 - Jun 2022</span>
                            <h1 className="font-bold">CONSORD</h1>
                            <h2>End-of-Studies Internship</h2>
                        </div>
                        <div className="mt-8">
                            <span className="text-textColor font-bold">Jul 2021 - Sep 2021</span>
                            <h1 className="font-bold">INSTEAD</h1>
                            <h2>Intership Development</h2>
                        </div>
                        <div className="mt-8">
                            <span className="text-textColor font-bold">Jan 2020 - Feb 2020</span>
                            <h1 className="font-bold">SAGEMCOM</h1>
                            <h2>Internship observation</h2>
                        </div>
                    </div>
                </div>

            </div>


        </div>
    );
}

export default Carrer;